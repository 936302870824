import moment from "moment";

const has = Object.prototype.hasOwnProperty;

export const isDiff = (A, B) => JSON.stringify(A) !== JSON.stringify(B);

export const isEmpty = (prop) => {
  return (
    prop === null ||
    prop === undefined ||
    (has.call(prop, "length") && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
  );
};

export const formatDateAndTime = (date, time) => {
  return moment(`${date} ${time}`, "YYYYMMDD HHmmss").format("YYYY-MM-DD HH:mm");
};
