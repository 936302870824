import React, { useEffect, useState } from "react";
import { Modal, message, Button, Spin, Table } from "antd";
import { useIntl } from "react-intl";
import { actionGetExportData } from "../StudyListAction";

const columns = [
  {
    title: "Study ID",
    dataIndex: "study_id",
    key: "study_id",
  },
];

let params = {};

const ExportDataModal = (props) => {
  const { formatMessage: t } = useIntl();
  const { visible = true, onCancel, projectId, selectedRowItems } = props;
  const [processing, setProcessing] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (projectId) {
      params = {
        ...params,
        project_id: projectId,
      };
    }
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    let data = [];
    selectedRowItems.forEach((selectedRowItem) => {
      selectedRowItem.dicom_tags.StudyInstanceUID.forEach(async (study_id) => {
        data = [
          ...data,
          {
            key: study_id,
            study_id,
          },
        ];
      });
    });

    if (!data || data.length === 0) {
      return;
    }
    setDataSource(data);
  }, [selectedRowItems]);

  const handleOk = async (event) => {
    if (processing || selectedRowItems.length === 0) {
      return;
    }

    try {
      setProcessing(true);

      const actions = selectedRowItems.flatMap((selectedRowItem) =>
        selectedRowItem.dicom_tags.StudyInstanceUID.map(
          (study_id) => async () => {
            params = {
              ...params,
              study_id,
            };

            await actionGetExportData(params);
            message.success(`Exported file ${study_id}`);
          }
        )
      );

      await actions.reduce(
        (prev, action) => prev.then(action),
        Promise.resolve()
      );
    } catch (error) {
      const { data = {} } = error || {};
      message.error(data.message || "No file to download");
      setProcessing(false);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      title={t({ id: "IDS_EXPORT_DATA" })}
      visible={visible}
      className="common-modal"
      onCancel={onCancel}
      width={900}
      maskClosable={false}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          {t({ id: "IDS_EXPORT" })}
        </Button>,
      ]}
    >
      <div className="export-label-modal">
        <Spin spinning={processing}>
          <div className="form-content">
            <Table
              size="small"
              scroll={{ y: 250 }}
              rowKey={(record) => record.id}
              className="exported-table"
              dataSource={dataSource || []}
              columns={columns}
              pagination={false}
            />
          </div>
        </Spin>
      </div>
    </Modal>
  );
};

export default ExportDataModal;
